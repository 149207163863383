import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      'date': 'Date',
      'Circuit Name': 'Circuit Name',
      'Circuit Size': 'Circuit Size',
      'Team': 'Team',
      'BIKE': 'BIKE',
      'Factory Team': 'Factory Team',
      'Age': 'Age',
      'years': 'years',
      'Number of points': 'Number of points',
      'Points': 'Points',
      'Position in Championship': 'Position in Championship',
      'Close': 'Close',
      'Rider': 'Rider',
      'Team': 'Team',
    },
  },
  fr: {
    translation: {
      'date': 'Date',
      'Circuit Name': 'Nom du Circuit',
      'Circuit Size': 'Taille du Circuit',
      'Team': 'Équipe',
      'BIKE': 'MOTO',
      'Factory Team': 'Équipe usine',
      'Age': 'Âge',
      'years': 'ans',
      'Number of points': 'Nombre de points',
      'Points': 'Points',
      'Position in Championship': 'Place dans le chanpionnat',
      'Close': 'Fermer',
      'Rider': 'Pilote',
      'Team': 'Équipe',

    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'fr',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
