import React, { Component } from 'react';
import { apiCall } from '../utils/api'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import RiderModal from './RiderModal';

class Riders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      riders: [],
      selectedRider: null
    };
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    this.fetchRiders();
  }

  componentWillUpdate(nextProps) {
    if (nextProps.season.gp_season_id !== this.props.season.gp_season_id) {
      this.state.selectedRider = 0
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.season.gp_season_id !== this.props.season.gp_season_id) {
      this.fetchRiders();
    }
  }

  fetchRiders() {
    const { season } = this.props;
    apiCall(`get_datas_by_riders&j=${season.gp_season_id}`)
      .then(ridersData => {
        const ridersArray = Object.values(ridersData);
        this.setState({ riders: ridersArray });
      })
      .catch(error => {
        console.error('Error fetching riders:', error);
      });
  }

  handleRiderClick = (rider) => {
    this.setState({ selectedRider: rider });
  };

  render() {
    const { season } = this.props;
    const { riders, selectedRider } = this.state;
    return (
      <div className="container">
        <h2>{ season && season.gp_season_name }</h2>
        <div className="row row-cols-4">
          {riders.map((rider, index) => (
            <div className="card col" key={index} onClick={() => this.handleRiderClick(rider)}>
              <div className="card-body">
                <h3 className="panel-title">{rider.gp_riders_id}</h3>
                <div className="panel-body">
                  {rider.gp_riders_firstname} {rider.gp_riders_name}
                </div>
              </div>
            </div>
          ))}
        </div>
        <RiderModal rider_datas={selectedRider} seasonId={season.gp_season_id} seasonName={season.gp_season_name} modalRef={this.modalRef} />
      </div>
    );
  }
}

export default Riders;
