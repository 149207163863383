import axios from 'axios';

const baseURL = 'https://gp.anthonybois.fr/api/api.php?i=';

export const apiCall = async (endpoint) => {
  try {
    const response = await axios.get(baseURL + endpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
