import React, { useEffect, useState } from 'react';
import { apiCall } from '../utils/api';
import { Modal } from 'bootstrap'
import './RiderModal.scss';
import { withTranslation } from 'react-i18next';

function RiderModal({ rider_datas, seasonId, seasonName, modalRef, t }) {
  const [rider, setRider] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setRider(rider_datas);
        let myModal = new Modal(modalRef.current);
        myModal.show();

        myModal._element.addEventListener('hidden.bs.modal', handleModalHidden);

      } catch (error) {
        console.error('Error fetching rider details:', error);
      }
    };
    
    if(rider_datas)
        fetchData();

    return () => {
      let myModal = new Modal(modalRef.current);
      myModal._element.removeEventListener('hidden.bs.modal', handleModalHidden);
    };
  }, [rider_datas, seasonId]);

  const handleModalHidden = () => {
    setRider(null);
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <div className="modal fade" id="riderModal" tabIndex="-1" aria-labelledby="riderModalLabel" aria-hidden="true" ref={modalRef}>
      <div className="modal-dialog">
        {rider && (
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="riderModalLabel">
                <img src={`https://flagcdn.com/56x42/${rider.gp_country_iso_code}.png`} alt={rider.gp_country_iso_code} /> 
                {rider.gp_riders_firstname} {rider.gp_riders_name} {rider.gp_riders_id}
              </h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label={t('Close')}></button>
            </div>
            <div className="modal-body">
              <div className="rider-img-container">
                <img className="rider-img" height="100%" src={`https://gp.anthonybois.fr/img/pilotes/${seasonName}/${String(rider.gp_riders_id).padStart(2, '0')}.png`} alt={`${rider.gp_riders_firstname} ${rider.gp_riders_name} ${rider.gp_riders_id}`} />
                <div className="gradient-overlay"></div>
              </div>
              <div className="row justify-content-center">
                <div className="col-6">
                  <p>{t('Team')}: {rider.gp_team_name}</p>
                  <p>{t('BIKE')}: {rider.gp_bike_name}</p>
                  <p>{rider.gp_team_factory == 1 ? t('Factory Team') : ''}</p>
                </div>
                <div className="col-6">
                  <p>{t('Age')}: {calculateAge(rider.gp_riders_birth_date)} {t('years')} ({rider.gp_riders_birth_date})</p>
                  <p>{t('Points')}: {rider.points}</p>
                  <p>{t('Position in Championship')}: {rider.position}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withTranslation()(RiderModal);
