// Year.js
import React, { useState, useEffect } from 'react';
import { apiCall } from '../utils/api';

function Year({ onSelectSeason }) {
    const [seasons, setSeasons] = useState([]);

    useEffect(() => {
        apiCall('get_seasons')
            .then(seasonsData => {
                const seasonsArray = Object.values(seasonsData);
                setSeasons(seasonsArray);
                const currentYear = new Date().getFullYear();
                const currentSeason = seasonsArray.filter(season => season.gp_season_name === currentYear.toString());
                if (currentSeason.length > 0) {
                    onSelectSeason(currentSeason[0]);
                }
            })
            .catch(error => {
                console.error('Error fetching seasons:', error);
            });
    }, []);

    const handleSeasonSelect = (season) => {
        onSelectSeason(season);
    };

    return (
        <div>
            {seasons.map((season, index) => (
                <a className="link" key={index} onClick={() => handleSeasonSelect(season)}>{season.gp_season_name}</a> 
            ))}
        </div>
    );
}

export default Year;
